<template>
<div style="background: #F8F8F8;">
  <div class="contentbody" style="">
    <nodata v-if="getters.code&&getters.code==10001" :code='getters.code' :list='[]' />
    <div v-else style="display: flex;">
      <!-- <el-row> -->
        <div class="menucol">
          <ul class="menu">
            <li :class="store.state.app.menu_title=='AboutTR35'?'active':''" @click="selClick('AboutTR35')">{{t("menu.AboutTR35")}}</li>
            <li :class="store.state.app.menu_title=='Criteria'?'active':''" @click="selClick('Criteria')">{{t("menu.SelectionRules")}}</li>
            <li :class="store.state.app.menu_title=='Applicationguide'?'active':''" @click="selClick('Applicationguide')">{{t("menu.RegistrationInstructions")}}</li>
            <li :class="store.state.app.menu_title=='FAQ'?'active':''" @click="selClick('FAQ')">{{t("menu.FAQ")}}</li>
          </ul>
        </div>
        <div class="concol">
          <div class="title" v-if="adoutData.cn_title || adoutData.en_title">
            {{ language == "cn" ? adoutData.cn_title : adoutData.en_title }}
          </div>
          <div class="content" v-html="adoutData.cn_content" v-if="language == 'cn'"></div>
          <div class="content" v-html="adoutData.en_content" v-else></div>
          <div class="btn" v-if="adoutData.attachments">
            <button @click="btnclick(adoutData.attachments)">{{
              t("button.download")
              }}</button>
          </div>
        </div>
      <!-- </el-row> -->
    </div>
  </div>
  </div>
</template>
<script>
  import { defineComponent, ref, computed, watch, reactive } from "vue";
  import { useGetters } from "@/store/use.js";
  import { useRouter, onBeforeRouteUpdate } from "vue-router";
  import store from "@/store";
  import { useI18n } from "vue-i18n";
  import nodata from "@/pages/nodata.vue";
  export default defineComponent({
    name: "关于",
    components: {
      nodata,
    },
    setup() {
      const { t, locale } = useI18n();
      const router = useRouter();
      const getters = useGetters(["language", "adoutData", "code","menu_title","activeIndex"]);
      const language = getters.language;
      const query = router.currentRoute.value.query;
      const title=ref(query.title);
      store.dispatch("app/getAboutData", getters.menu_title.value)
      store.state.app.menu_title=getters.menu_title.value;
      const adoutData = getters.adoutData;
      const btnclick = (href) => {
        window.open(href);
      };
      const selClick=(v)=>{
         title.value=v;
         store.state.app.menu_title=v;
         store.dispatch("app/getAboutData", v);
         localStorage.setItem('menu_title',v)
         
      }
      return {
        adoutData,
        language,
        btnclick,
        t,
        selClick,
        title,
        getters,
        query,store,
      };
    },
  });
</script>
<style lang="scss" scoped>
  .contentbody {
    width: 1200px;
    margin: 20px auto 30px;
    .concol,.menu{
      // background: #F4F7FA;
      // border: 1px solid #E4E4E4;
      
    }
    .concol{
      width: 954px;
      padding: 30px;
      margin-left: 6px;
      background: #fff;
      min-height: calc(100vh - 300px);
      .content {
        line-height: 28px;
        font-size: 16px;
      }
    }
    
    .menu{
      min-height: calc(100vh - 300px);
      width: 240px;
      border-right: 0px;
       background: #fff;
      li{line-height: 80px;text-align: center;cursor: pointer;font-size: 18px;}
      .active{
        background: #fff;
        // border: 1px solid #E4E4E4;
        background: #ED3524;
        color: #fff;
      }
    }
    .title {
      font-size: 30px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 50px;
      line-height: 42px;
      margin-top: 30px;
    }

    .btn {
      margin-top: 38px;
      text-align: center;
      button {
        border: 1px solid #E34C3E;
        color: #E34C3E;
        background: #fff;
        font-size: 16px;
        width: 138px;
        height: 38px;
        cursor: pointer;
      }

      button:hover {
        background: #E34C3E;
         color: #fff;
      }
    }
  }

  @media screen and (min-width: 375px) and (max-width: 750px) {
    .contentbody {
      width: 95%;
      .menucol{display: none;}
      .concol{max-width: 100%;flex:100%}
    }
  }
</style>