<template>
    <div class="no" style="height: calc(100vh - 300px);">
        <el-empty style="padding: 170px 0;" :image="img_url"  :image-size="200" :description="!description?''+t('tip.loadingFailed')+'':''+t('tip.nodata')+''">
          <el-button v-if="flag" class="back" type="primary" @click="back">{{t('button.back')}}</el-button>
        </el-empty>
    </div>
</template>
<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import store from '@/store'
import { useI18n } from 'vue-i18n'
export default defineComponent({
    name:'暂无数据',
    props:{
        list:{
            type:Array,
            default:[],
        },
        code:{
            type:Number,
            default:0,
        },
        flag:{
            type:Boolean,
             default:true,
        }
    },
    setup(props) {
        const { t, locale } = useI18n()
        const router = useRouter();
        const img_url=ref(props.code==10001&&props.list<1?require('@/assets/images/Loadingfailed.png'):require('@/assets/images/nodata.png'));
        const description=ref(props.code==10001&&props.list<1?false:true);
        const back=()=>{
            sessionStorage.setItem('activeIndex',1);
            store.dispatch('app/setactiveIndex', 1);
            router.push('/index');
        }
        return{
            t,
            back,
            img_url,
            description
        }
        
    },
})
</script>

<style lang='scss' scoped>
:deep(.el-empty){
    p{
    font-size: 20px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    }
}
.back{
    width: 170px;
    height: 48px;
    background: #ED3524;
    border-radius: 4px;
    margin-top: 8px;
    span{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 25px;
    }
}
</style>